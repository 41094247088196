<template>
  <g>
    <svg:style>
      .cls-3 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        fill: url(#glass-pattern);
      }
    </svg:style>
    <g id="B05">
      <polyline
        id="glass"
        class="cls-3"
        :points="`${doorLeftWidth1 + 41.41} ${doorTopHeight1 + 240.9} ${doorLeftWidth1 + 65.86} ${doorTopHeight1 + 240.9} ${doorLeftWidth1 + 65.86} ${doorTopHeight1 + 59.92} ${doorLeftWidth1 + 40.41} ${doorTopHeight1 + 59.92} ${doorLeftWidth1 + 40.41} ${doorTopHeight1 + 240.9}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    }
  },
}
</script>
